/* ==========================================================================
   Normalize.scss settings
   ========================================================================== */
/**
 * Includes legacy browser support IE6/7
 *
 * Set to false if you want to drop support for IE6 and IE7
 */
/* Base
   ========================================================================== */
/**
 * 1. Set default font family to sans-serif.
 * 2. Prevent iOS and IE text size adjust after device orientation change,
 *    without disabling user zoom.
 * 3. Corrects text resizing oddly in IE 6/7 when body `font-size` is set using
 *  `em` units.
 */
/* line 23, ../sass/_normalize.scss */
html {
  font-family: sans-serif;
  /* 1 */
  -ms-text-size-adjust: 100%;
  /* 2 */
  -webkit-text-size-adjust: 100%;
  /* 2 */
}

/**
 * Remove default margin.
 */
/* line 36, ../sass/_normalize.scss */
body {
  margin: 0;
}

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
/* line 50, ../sass/_normalize.scss */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
/* line 71, ../sass/_normalize.scss */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/**
 * Prevents modern browsers from displaying `audio` without controls.
 * Remove excess height in iOS 5 devices.
 */
/* line 89, ../sass/_normalize.scss */
audio:not([controls]) {
  display: none;
  height: 0;
}

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
/* line 100, ../sass/_normalize.scss */
[hidden],
template {
  display: none;
}

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
/* line 112, ../sass/_normalize.scss */
a {
  background-color: transparent;
}

/**
 * Improve readability of focused elements when they are also in an
 * active/hover state.
 */
/* line 122, ../sass/_normalize.scss */
a:active, a:hover {
  outline: 0;
}

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
/* line 134, ../sass/_normalize.scss */
abbr[title] {
  border-bottom: 1px dotted;
}

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
/* line 142, ../sass/_normalize.scss */
b,
strong {
  font-weight: bold;
}

/**
 * Address styling not present in Safari and Chrome.
 */
/* line 157, ../sass/_normalize.scss */
dfn {
  font-style: italic;
}

/**
 * Address variable `h1` font-size and margin within `section` and `article`
 * contexts in Firefox 4+, Safari, and Chrome.
 */
/* line 166, ../sass/_normalize.scss */
h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

/**
 * Addresses styling not present in IE 8/9.
 */
/* line 208, ../sass/_normalize.scss */
mark {
  color: #000;
  background: #ff0;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */
/* line 247, ../sass/_normalize.scss */
small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
/* line 255, ../sass/_normalize.scss */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

/* line 265, ../sass/_normalize.scss */
sup {
  top: -0.5em;
}

/* line 269, ../sass/_normalize.scss */
sub {
  bottom: -0.25em;
}

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
/* line 324, ../sass/_normalize.scss */
img {
  border: 0;
}

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
/* line 335, ../sass/_normalize.scss */
svg:not(:root) {
  overflow: hidden;
}

/* Grouping content
   ========================================================================== */
/**
 * Address margin not present in IE 8/9 and Safari.
 */
/* line 346, ../sass/_normalize.scss */
figure {
  margin: 1em 40px;
}

/**
 * Address differences between Firefox and other browsers.
 */
/* line 354, ../sass/_normalize.scss */
hr {
  box-sizing: content-box;
  height: 0;
}

/**
 * Contain overflow in all browsers.
 */
/* line 363, ../sass/_normalize.scss */
pre {
  overflow: auto;
}

/**
 * Address odd `em`-unit font size rendering in all browsers.
 * Correct font family set oddly in IE 6, Safari 4/5, and Chrome.
 */
/* line 372, ../sass/_normalize.scss */
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em;
}

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
/* line 399, ../sass/_normalize.scss */
button,
input,
optgroup,
select,
textarea {
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */
  color: inherit;
  /* 1 */
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
/* line 419, ../sass/_normalize.scss */
button {
  overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
/* line 430, ../sass/_normalize.scss */
button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
/* line 445, ../sass/_normalize.scss */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  /* 3 */
  -webkit-appearance: button;
  /* 2 */
}

/**
 * Re-set default cursor for disabled elements.
 */
/* line 460, ../sass/_normalize.scss */
button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */
/* line 469, ../sass/_normalize.scss */
button::-moz-focus-inner,
input::-moz-focus-inner {
  padding: 0;
  border: 0;
}

/**
 * Address Firefox 4+ setting `line-height` on `input` using `!important` in
 * the UA stylesheet.
 */
/* line 481, ../sass/_normalize.scss */
input {
  line-height: normal;
}

/**
 * 1. Address box sizing set to `content-box` in IE 8/9/10.
 * 2. Remove excess padding in IE 8/9/10.
 *  Known issue: excess padding remains in IE 6.
 */
/* line 491, ../sass/_normalize.scss */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
/* line 507, ../sass/_normalize.scss */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Address `appearance` set to `searchfield` in Safari and Chrome.
 * 2. Address `box-sizing` set to `border-box` in Safari and Chrome.
 */
/* line 517, ../sass/_normalize.scss */
input[type="search"] {
  box-sizing: content-box;
  /* 2 */
  -webkit-appearance: textfield;
  /* 1 */
}

/**
 * Remove inner padding and search cancel button in Safari and Chrome on OS X.
 * Safari (but not Chrome) clips the cancel button when the search input has
 * padding (and `textfield` appearance).
 */
/* line 528, ../sass/_normalize.scss */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * Define consistent border, margin, and padding.
 */
/* line 537, ../sass/_normalize.scss */
fieldset {
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
  border: 1px solid #c0c0c0;
}

/**
 * 1. Correct `color` not being inherited in IE 8/9/10/11.
 * 2. Remove padding so people aren't caught out if they zero out fieldsets.
 * 3. Corrects text not wrapping in Firefox 3.
 * 4. Corrects alignment displayed oddly in IE 6/7.
 */
/* line 551, ../sass/_normalize.scss */
legend {
  padding: 0;
  /* 2 */
  border: 0;
  /* 1 */
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
/* line 566, ../sass/_normalize.scss */
textarea {
  overflow: auto;
}

/**
 * Don't inherit the `font-weight` (applied by a rule above).
 * NOTE: the default cannot safely be changed in Chrome and Safari on OS X.
 */
/* line 575, ../sass/_normalize.scss */
optgroup {
  font-weight: bold;
}

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
/* line 586, ../sass/_normalize.scss */
table {
  border-spacing: 0;
  border-collapse: collapse;
}

/* line 591, ../sass/_normalize.scss */
td,
th {
  padding: 0;
}

/* line 4, ../sass/screen.scss */
html {
  background: #003e4f;
  color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
}

/* line 11, ../sass/screen.scss */
body {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1440px;
  min-height: 100vh;
}
/* line 18, ../sass/screen.scss */
body * {
  box-sizing: border-box;
}

/* line 23, ../sass/screen.scss */
a,
button {
  text-decoration: none;
  transition: 0.2s;
  will-change: color;
}

/* line 31, ../sass/screen.scss */
img[class*='iconic'] {
  visibility: hidden;
}

/* line 36, ../sass/screen.scss */
.sr-only {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* line 49, ../sass/screen.scss */
.mark-fill, .mark-text,
.iconic-property-fill,
.iconic-property-text {
  fill: currentColor;
}
/* line 54, ../sass/screen.scss */
.mark-stroke,
.iconic-property-stroke {
  stroke: currentColor;
}

/* line 59, ../sass/screen.scss */
.site-header {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  margin: 0 auto 16px;
  padding: 16px 16px 0;
  width: 100%;
}
@media (max-width: 600px) {
  /* line 59, ../sass/screen.scss */
  .site-header {
    flex-wrap: wrap;
  }
}
/* line 73, ../sass/screen.scss */
.site-header-spacer {
  display: block;
  flex: 1 1 auto;
}
/* line 78, ../sass/screen.scss */
.site-header button {
  background: transparent;
  border: 0;
  color: #fff;
  display: inline-block;
  outline: none;
  padding: 0;
}
/* line 87, ../sass/screen.scss */
.site-header .nav-toggle {
  margin-left: 16px;
  position: relative;
  z-index: 10;
}
/* line 92, ../sass/screen.scss */
.category-list .site-header .nav-toggle {
  display: none;
}
/* line 96, ../sass/screen.scss */
.site-header .nav-toggle .iconic-x-thin {
  display: none;
}
/* line 99, ../sass/screen.scss */
.show-nav .site-header .nav-toggle .iconic-x-thin {
  display: block;
}
/* line 105, ../sass/screen.scss */
.show-nav .site-header .nav-toggle .iconic-menu {
  display: none;
}
@media (min-width: 1024px) {
  /* line 87, ../sass/screen.scss */
  .site-header .nav-toggle {
    display: none;
  }
}

/* line 116, ../sass/screen.scss */
.site-branding {
  align-self: flex-start;
  color: #fff;
  display: inline-block;
  margin-right: 16px;
  will-change: opacity;
}
/* line 123, ../sass/screen.scss */
.site-branding:hover, .site-branding:focus, .site-branding:active {
  opacity: 0.7;
}
/* line 129, ../sass/screen.scss */
.site-branding-text {
  font-size: 1.25rem;
  font-weight: 200;
  text-transform: uppercase;
}
/* line 135, ../sass/screen.scss */
.site-branding .mark {
  display: block;
  height: auto;
  width: 180px;
}

/* line 142, ../sass/screen.scss */
.site-nav {
  background: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  display: none;
  left: 0;
  padding: 8px;
  position: absolute;
  right: 0;
  top: 92px;
  width: 100%;
  z-index: 10;
}
/* line 155, ../sass/screen.scss */
.show-nav .site-nav {
  display: block;
}
/* line 159, ../sass/screen.scss */
.category-list .site-nav {
  display: none;
}
@media (min-width: 1024px) {
  /* line 142, ../sass/screen.scss */
  .site-nav {
    background: transparent;
    box-shadow: none;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: -6px;
    padding: 0;
    position: static;
  }
  /* line 174, ../sass/screen.scss */
  .category-list .site-nav {
    display: none;
  }
}
/* line 179, ../sass/screen.scss */
.site-nav-item {
  color: #003e4f;
  display: block;
  margin-top: 16px;
  padding: 8px;
}
/* line 185, ../sass/screen.scss */
.site-nav-item:first-child {
  margin-top: 0;
}
@media (min-width: 1024px) {
  /* line 179, ../sass/screen.scss */
  .site-nav-item {
    align-self: flex-end;
    background: rgba(255, 255, 255, 0);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: #fff;
    display: inline-block;
    flex: 0 1 auto;
    font-size: 0.875rem;
    margin-right: 8px;
    margin-top: 0;
    overflow: hidden;
    padding: 16px;
    text-align: center;
  }
  /* line 204, ../sass/screen.scss */
  .site-nav-item:hover, .site-nav-item:focus, .site-nav-item:active {
    background-color: rgba(255, 255, 255, 0.2);
  }
}
/* line 211, ../sass/screen.scss */
.site-nav-item-active {
  font-weight: 600;
  text-transform: uppercase;
}
@media (min-width: 1024px) {
  /* line 211, ../sass/screen.scss */
  .site-nav-item-active {
    background: #fff;
    color: #003e4f;
    font-weight: 400;
    text-transform: none;
  }
  /* line 221, ../sass/screen.scss */
  .site-nav-item-active:hover, .site-nav-item-active:focus, .site-nav-item-active:active {
    background-color: white;
  }
}

/* line 231, ../sass/screen.scss */
.page-content {
  background: #fff;
  border-radius: 6px;
  color: #444;
  flex: 1 0 auto;
  padding: 16px;
  width: 100%;
}
/* line 239, ../sass/screen.scss */
.category-list .page-content {
  background: #f2f2f2;
}
/* line 243, ../sass/screen.scss */
.page-content .page-title {
  color: #003e4f;
  font-weight: 200;
  letter-spacing: -1px;
}
/* line 248, ../sass/screen.scss */
.page-content .page-title:first-child {
  margin-top: 0.5em;
}
/* line 252, ../sass/screen.scss */
.page-content .page-title-search-term {
  font-size: 0.75em;
  font-style: italic;
}
/* line 258, ../sass/screen.scss */
.page-content table {
  margin: -8px;
  width: calc(100% + 16px);
}
@media (max-width: 480px) {
  /* line 258, ../sass/screen.scss */
  .page-content table {
    font-size: 0.875rem;
  }
}
/* line 266, ../sass/screen.scss */
.page-content table td,
.page-content table th {
  padding: 8px;
  text-align: left;
  transition: 0.2s;
  vertical-align: center;
  will-change: background-color;
}
/* line 276, ../sass/screen.scss */
.page-content table thead th,
.page-content table thead td {
  font-weight: 200;
  letter-spacing: -1px;
  text-transform: uppercase;
}
/* line 287, ../sass/screen.scss */
.page-content table tbody tr:nth-child(odd) th,
.page-content table tbody tr:nth-child(odd) td {
  background: rgba(0, 0, 0, 0.05);
}
/* line 294, ../sass/screen.scss */
.page-content table tbody tr:hover th,
.page-content table tbody tr:hover td {
  background: rgba(242, 162, 70, 0.2);
}
/* line 302, ../sass/screen.scss */
.page-content table .document-list-header {
  font-size: 1.25rem;
}
/* line 307, ../sass/screen.scss */
.page-content table .document-list-item-name {
  font-weight: 400;
}
/* line 310, ../sass/screen.scss */
.page-content table .document-list-item-name p {
  font-size: 0.875em;
  font-style: italic;
  margin: 0.5em 0;
}
/* line 315, ../sass/screen.scss */
.page-content table .document-list-item-name p:last-child {
  margin-bottom: 0;
}
/* line 321, ../sass/screen.scss */
.page-content table .document-list-item-link {
  color: #003e4f;
}
/* line 325, ../sass/screen.scss */
.page-content table .document-list-item-icon {
  color: rgba(0, 62, 79, 0.5);
  line-height: 1;
  width: 1px;
}
/* line 331, ../sass/screen.scss */
.page-content table .document-list-item-date {
  white-space: nowrap;
}

/* line 338, ../sass/screen.scss */
.site-footer {
  color: #809fa7;
  font-size: 0.75rem;
  padding: 16px;
  text-align: center;
  width: 100%;
}
/* line 345, ../sass/screen.scss */
.site-footer a {
  color: inherit;
  text-decoration: underline;
}

/* line 1, ../sass/_forms.scss */
.form {
  display: block;
}
/* line 4, ../sass/_forms.scss */
.form button {
  background: #003e4f;
  border: 0;
  border-radius: 3px;
  color: #fff;
  padding: 8px 16px;
}
/* line 12, ../sass/_forms.scss */
.form-search {
  position: relative;
  z-index: 1;
}
@media (max-width: 600px) {
  /* line 12, ../sass/_forms.scss */
  .form-search {
    margin-top: 16px;
    order: 3;
    width: 100%;
  }
}
/* line 22, ../sass/_forms.scss */
.form-search button {
  background: transparent;
  color: #003e4f;
  padding: 0 8px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%) scale(0.75);
  transform-origin: center right;
  z-index: 2;
}
/* line 35, ../sass/_forms.scss */
.form label {
  display: block;
  margin: 0 auto 0.25em;
}
/* line 40, ../sass/_forms.scss */
.form-header {
  font-weight: 700;
  text-align: center;
}
/* line 45, ../sass/_forms.scss */
.form-input {
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 3px;
  border-top-color: #999;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.18);
  color: #444;
  display: block;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  font-size: 1rem;
  font-weight: normal;
  max-width: 100%;
  padding: 0.5rem;
  transition: 0.2s border-color, 0.2s box-shadow;
  width: 100%;
}
/* line 61, ../sass/_forms.scss */
.form-input:focus {
  border-color: #2188ff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px #2188ff;
  outline: none;
}
/* line 67, ../sass/_forms.scss */
.form-search .form-input:focus + button {
  color: #2188ff;
}
/* line 73, ../sass/_forms.scss */
.form-search .form-input {
  padding-right: 36px;
}
/* line 78, ../sass/_forms.scss */
.form select {
  background-color: #fff;
  border: 1px solid #bbb;
  border-radius: 3px;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06);
  height: 36px;
  max-width: 100%;
  transition: 0.2s border-color, 0.2s box-shadow;
  width: 100%;
}
/* line 88, ../sass/_forms.scss */
.form select:focus {
  border-color: #2188ff;
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.06), 0 0 5px #2188ff;
  outline: none;
}
/* line 95, ../sass/_forms.scss */
.form-row, .form-buttons {
  margin: 1rem auto 0;
}
/* line 99, ../sass/_forms.scss */
.form-row:first-child, .form-buttons:first-child {
  margin-top: 0;
}
/* line 104, ../sass/_forms.scss */
.form-buttons {
  text-align: right;
}

/* line 109, ../sass/_forms.scss */
.login-form {
  align-self: center;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  margin: 0 auto;
  max-width: 100%;
  padding: 1rem;
  width: 360px;
}
/* line 119, ../sass/_forms.scss */
.login-form .form-header {
  background: url("../img/mark.svg") no-repeat top center;
  background-size: 100% auto;
  padding-top: 25%;
}

/* line 354, ../sass/screen.scss */
.show-nav .overlay {
  background: rgba(68, 68, 68, 0.8);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
}

/* line 365, ../sass/screen.scss */
.cat-list {
  align-items: stretch;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  list-style: none;
  margin: -8px;
  padding: 0;
}
/* line 374, ../sass/screen.scss */
.cat-list-item {
  flex: 1 1 auto;
  margin: 8px;
  min-height: 10rem;
  width: 288px;
}
/* line 380, ../sass/screen.scss */
.cat-list-item-link {
  align-items: center;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  color: #003e4f;
  display: flex;
  font-size: 1.5rem;
  justify-content: center;
  min-height: 100%;
  overflow: hidden;
  padding: 16px;
  text-align: center;
  width: 100%;
  will-change: background-color;
}
/* line 396, ../sass/screen.scss */
.cat-list-item-link:hover, .cat-list-item-link:focus, .cat-list-item-link:active {
  background-color: #f2f5f6;
}

/* line 405, ../sass/screen.scss */
.pagination {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  list-style: none;
  margin: 2rem auto 0;
  padding: 0;
  width: 100%;
}
/* line 416, ../sass/screen.scss */
.pagination > li {
  flex: 1 1 auto;
  margin: 0 1rem;
}
/* line 420, ../sass/screen.scss */
.pagination > li:first-child {
  margin-left: 0;
}
/* line 424, ../sass/screen.scss */
.pagination > li:last-child {
  margin-right: 0;
}
/* line 431, ../sass/screen.scss */
.pagination-prev:empty, .pagination-next:empty {
  visibility: hidden;
}
/* line 435, ../sass/screen.scss */
.pagination-prev a, .pagination-next a {
  display: inline-block;
  position: relative;
  z-index: 1;
}
/* line 443, ../sass/screen.scss */
.pagination-prev a {
  padding-left: 1.5rem;
}
/* line 446, ../sass/screen.scss */
.pagination-prev a::before {
  content: '\02190';
  left: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
/* line 456, ../sass/screen.scss */
.pagination-next {
  text-align: right;
}
/* line 459, ../sass/screen.scss */
.pagination-next a {
  padding-right: 1.5rem;
}
/* line 462, ../sass/screen.scss */
.pagination-next a::before {
  content: '\02192';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
/* line 472, ../sass/screen.scss */
.pagination a {
  color: #444;
}
/* line 475, ../sass/screen.scss */
.pagination a:hover, .pagination a:active, .pagination a:focus {
  color: #003e4f;
}

/* line 483, ../sass/screen.scss */
.login-page {
  background: #e6eced;
}
/* line 486, ../sass/screen.scss */
.login-page body {
  background: transparent;
}
/* line 490, ../sass/screen.scss */
.login-page .page-content {
  background: transparent;
  display: flex;
}
